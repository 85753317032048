import {
  Button,
  Flex,
  Input,
  Text,
  useToast,
  Link,
  Card,
} from "@chakra-ui/react"
import { signIn } from "next-auth/react"
import Image from "next/image"
import Router from "next/router"
import { useState } from "react"
import wb_logo from "../../assets/wb_logo.png"

const Login = () => {
  const toasts = useToast()

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [loading, setLoading] = useState(false)

  const onLogin = async () => {
    setLoading(true)
    const res = await signIn("jaete", {
      redirect: false,
      email: email,
      password: password,
    })
    setLoading(false)
    if (res?.error) {
      toasts({
        title: "Kirjautuminen epäonnistui",
        description: res.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } else if (res?.ok) {
      Router.replace("/dashboard")
    }
  }
  return (
    <Flex justify={"center"} align="center" w="100%" h="100vh">
      <Card border="1px solid var(--darkGreen)" p="30px" rounded={"3xl"}>
        <Image src={wb_logo} alt="Wastebook" width={192} height={52} />
        <Text mt="40px">Sähköposti</Text>
        <Input
          autoComplete={"email"}
          onChange={(e) => setEmail(e.target.value)}
          type={"username"}
        />
        <Text mt="20px">Salasana</Text>
        <Input onChange={(e) => setPassword(e.target.value)} type="password" />
        <Link href={"https://cloud.jaete.fi/"} target="_blank">
          <Text mt="10px">Unohtuiko salasanan</Text>
        </Link>

        <Button
          isLoading={loading}
          onClick={onLogin}
          mt="20px"
          color="white"
          w="100%"
          background={"var(--darkGreen)"}
        >
          Kirjaudu sisään
        </Button>
      </Card>
    </Flex>
  )
}

export default Login
